import React from "react";
import theme from "theme";
import { Theme, Icon, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaBrain, FaCoins, FaUserShield } from "react-icons/fa";
import { MdAlarm, MdAccessibility } from "react-icons/md";
import { IoIosChatbubbles } from "react-icons/io";
import { BsEyeFill } from "react-icons/bs";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Profilozó Személyiségteszt | Sziklay Bence
			</title>
			<meta name={"description"} content={"Értsd meg önmagad egy Enneagram alapú személyiségteszttel! Lépj az önfelfedezés útjára most!"} />
			<meta property={"og:title"} content={"Profilozó Személyiségteszt | Sziklay Bence"} />
			<meta property={"og:description"} content={"Értsd meg önmagad egy Enneagram alapú személyiségteszttel! Lépj az önfelfedezés útjára most!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/creativeOG.webp?v=2024-08-06T08:29:54.434Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
		</Helmet>
		<Components.NavBar>
			<Override slot="text" />
			<Override slot="quarklycommunityKitMobileSidePanelOverride" />
			<Override slot="quarklycommunityKitMenuOverride2" />
			<Override slot="linkBox" />
			<Override slot="quarklycommunityKitMenuOverride" />
			<Override slot="text1" />
			<Override slot="icon" />
			<Override slot="image" />
			<Override slot="box3" />
			<Override slot="logo" />
			<Override slot="link1" />
			<Override slot="box1" />
			<Override slot="linkContact" />
			<Override slot="Button Icon" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="quarklycommunityKitMenu" />
		</Components.NavBar>
		<Section margin="0 0 44px 0">
			<Override slot="SectionContent" font="--base" />
			<Box
				min-width="100px"
				min-height="100px"
				margin="0px auto 50px auto"
				max-width="500px"
				border-width="2px"
				border-style="solid"
				border-radius="15px"
				border-color="--color-light"
				display="grid"
				align-items="center"
				justify-items="center"
				align-content="center"
				justify-content="center"
				padding="32px 15px 15px 15px"
				box-shadow="--xl"
				grid-gap="20px"
				color="--primary"
				background="linear-gradient(0deg,#ffffff 0%,rgba(255, 255, 255, 0.9) 16.2%,rgba(255, 255, 255, 0.8) 86.2%,#ffffff 100%) top no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6602d5961d712a0023322e52/images/hexagbg.jpg?v=2024-06-17T18:15:32.524Z) center/cover no-repeat scroll padding-box"
			>
				<Components.Spinner>
					<Icon
						category="fa"
						icon={FaBrain}
						size="64px"
						color="--amaranth"
						position="absolute"
						top="6px"
						opacity="0.5"
						left="6px"
						bottom={0}
						width="48px"
						height="48px"
					/>
				</Components.Spinner>
				<Box min-width="100px" min-height="100px" quarkly-title="Title Wrapper" margin="0px 0px 15px 0px">
					<Text
						margin="0px 0px 0px 0px"
						font="normal 700 2em/1.2 --fontFamily-googleLexend"
						as="h1"
						text-align="center"
						color="--secondary"
					>
						Személyiségteszt
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" as="h1" text-align="center">
						by Sziklay Bence
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px" quarkly-title="Description Wrapper">
					<Text margin="0px 0px 0px 0px" font="--lead" text-align="center">
						Kezdd el ezt a belső utazást, válaszolj őszintén, és fedezd fel a személyiséged ismeretlen oldalait!
					</Text>
					<Box
						display="grid"
						justify-content="flex-end"
						align-items="flex-end"
						opacity="0.8"
						grid-template-columns="1fr 1fr"
						margin="30px 0px 0px 0px"
					>
						<Box display="flex" justify-self="start" align-items="center" font="--base">
							<Icon
								category="fa"
								icon={FaCoins}
								size="64px"
								width="20px"
								align-self="flex-end"
								height="29px"
								margin="0px 10px 0px 0px"
							/>
							<Text margin="0px 0px 0px 0px" text-align="right" sm-text-align="left">
								3.900 Ft-tól
							</Text>
						</Box>
						<Box display="flex" justify-self="end" font="--base">
							<Icon
								category="md"
								icon={MdAlarm}
								size="64px"
								width="20px"
								align-self="flex-end"
								height="29px"
								margin="0px 10px 0px 0px"
							/>
							<Text margin="0px 0px 0px 0px" text-align="right">
								kb. 20 perc
							</Text>
						</Box>
					</Box>
				</Box>
				<Box position="relative">
					<Components.CtaButton href="/enneagram-test" height="150px">
						<Override
							slot="MotionButton"
							background="--color-light"
							border-width="5px"
							border-style="solid"
							border-color="--color-amaranth"
							color="--amaranth"
							font="normal 600 20px/1.5 --fontFamily-googleRubik"
							focus-box-shadow="none"
						>
							Indítás
						</Override>
					</Components.CtaButton>
				</Box>
			</Box>
			<Text display="grid" font="normal 900 26px/1.2 --fontFamily-googleLexend" color="--primary">
				Készen állsz felfedezni a személyiséged rejtett oldalait?
			</Text>
			<Text display="grid" font="--lead" max-width="80%" sm-max-width="100%">
				Minden pszichológiai elemzés végén azt várod, hogy végre kézzelfogható személyiség fejlesztési tervet kapj a kezedbe, ne csak egy leírást magadról. Ilyen szolgáltatás nem létezett….idáig!
				<br />
				<br />
				Számos pszichológussal éveken át dolgoztunk, hogy ne dobjál ki 20 percet az ablakon csak azért, hogy a végén megmondják: igen, te késős típus vagy, ellenben nagyon kreatív stb…köszi szépen, ezt tudtuk volna magunktól is.{"\n"}
			</Text>
		</Section>
		<Section margin="44px 0 0 0">
			<Box
				min-width="100px"
				min-height="100px"
				grid-gap="30px"
				grid-template-columns="repeat(2, 1fr)"
				display="grid"
				sm-grid-template-columns="1fr"
				font="--base"
				color="--primary"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="16px 16px 0px 16px"
					border-radius="15px"
					box-shadow="0 0 25px 3px rgba(0, 0, 0, 0.1)"
					display="grid"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						justify-content="space-between"
						align-items="center"
					>
						<Text display="grid" font="--lead" margin="16px 16px 16px 0px">
							Értsd meg önmagad!
						</Text>
						<Icon
							category="md"
							icon={MdAccessibility}
							size="48px"
							justify-self="center"
							color="--secondary"
						/>
					</Box>
					<Text display="grid" text-align="justify">
						Sallang helyett a profilozó elemzés segít megérteni a félelmeid valódi okát, a karaktered mögöttes motivációit, viselkedési mintáidat, elkerülési mechanizmusaidat, és utat mutat a fejlődéshez is! Az analízis széles körű perspektívát nyújt az emberi természetről, lehetővé téve számodra, hogy jobban megértsd önmagadat és másokat egyaránt. Ezáltal javítani tudod az üzleti kapcsolataidat, növelheted a hatékonyságodat a mindennapi életben, és fejlesztheted konfliktuskezelési képességeidet is.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="16px 16px 0px 16px"
					border-radius="15px"
					box-shadow="0 0 25px 3px rgba(0, 0, 0, 0.1)"
					display="grid"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						justify-content="space-between"
						align-items="center"
					>
						<Text display="grid" font="--lead" margin="16px 16px 16px 0px">
							Szólj azon a nyelven, amit a másik a legjobban ért!
						</Text>
						<Icon
							category="io"
							icon={IoIosChatbubbles}
							size="48px"
							justify-self="center"
							color="--secondary"
						/>
					</Box>
					<Text display="grid" text-align="justify">
						A szokványos tesztekkel ellentétben, tőlünk nem csak egy leírást kapsz. Konkrét, gyakorlatorientált tanácsokat adunk számodra, amelyek segítenek a személyiséged fejlesztésében, valamint abban, hogyan kommunikálj hatékonyan különböző személyiség típusokkal. A profilozó szemlélet lehetővé teszi, hogy az üzeneteidet finomhangolni tudjad az egyes személyiségtípusokhoz, ami növeli a kommunikációd hatékonyságát és pontosságát.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="16px 16px 0px 16px"
					border-radius="15px"
					box-shadow="0 0 25px 3px rgba(0, 0, 0, 0.1)"
					display="grid"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						justify-content="space-between"
						align-items="center"
					>
						<Text display="grid" font="--lead" margin="16px 16px 16px 0px">
							Láss át mások viselkedésén!
						</Text>
						<Icon
							category="bs"
							icon={BsEyeFill}
							size="48px"
							justify-self="center"
							color="--secondary"
						/>
					</Box>
					<Text display="grid" text-align="justify">
						A rendszer segítségével könnyebben azonosíthatod a különböző személyiségtípusok közti dinamikákat, ezáltal előrejelezheted és jobban kezelheted a különböző helyzeteket, legyen szó üzleti tárgyalásokról, csapattal való munkáról, vagy családi kihívásokról.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="16px 16px 0px 16px"
					border-radius="15px"
					box-shadow="0 0 25px 3px rgba(0, 0, 0, 0.1)"
					display="grid"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						justify-content="space-between"
						align-items="center"
					>
						<Text display="grid" font="--lead" margin="16px 16px 16px 0px">
							Biztonságos vásárlás{"\n\n"}
						</Text>
						<Icon
							category="fa"
							icon={FaUserShield}
							size="48px"
							justify-self="center"
							color="--secondary"
						/>
					</Box>
					<Text display="grid" text-align="justify">
						A vásárlás biztonságos PayPal fizetési rendszeren keresztül történik, elfogadjuk a főbb bankkártyákat is, és nem szükséges PayPal fiók létrehozása, csak menj rá a kártyás fizetésre. Ha bármilyen probléma adódna, kérlek, vedd fel velünk a kapcsolatot az info@sziklaybence.com email címen, lehetőség szerint csatolj egy képernyőképet a hibáról. Az alapár 3900 Ft, és az extra elemzési kérelmek függvényében változik.
					</Text>
				</Box>
				<Text grid-column="1/3" text-align="center" sm-grid-column="auto" font="--lead">
					A teszt rendkívül pontos, 135 kérdésből áll. Válaszolj ŐSZINTÉN, de ne feledd, hogy a személyes megfigyelések hiánya vagy pontatlansága miatt adódhatnak hibák.
				</Text>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text1" />
			<Override slot="link1" />
			<Override slot="box-right" />
			<Override slot="link2" />
			<Override slot="text" />
			<Override slot="box3" />
			<Override slot="link" />
			<Override slot="CreatorSignature" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b3caaa009abe001f026b4e"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});